<template>
  <form id="frm_action">
    <div class="p-formgrid">
        <div class="p-card-field">
          <div class="p-field p-grid">
            <label class="p-col-fixed cus-label-title">Tên gói <span style="color:red">*</span></label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col">
              <InputText required v-model="name" type="text" id="name_add" class="p-col-width" placeholder="Nhập tên gói"/>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed cus-label-title">Mô tả ngắn <span style="color:red">*</span></label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col">
              <InputText required v-model="description_short" type="text" id="description_short_add" class="p-col-width" maxLength="255" placeholder="Nhập mô tả ngắn"/>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed cus-label-title">Mô tả <span style="color:red">*</span></label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col">
              <Textarea placeholder="Nhập mô tả" v-model="description" id="description_add" :autoResize="true" rows="3" class="p-col-width "/>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed in-d cus-label-title">Loại gói</label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col">
              <Dropdown id="status_id" v-model="selType" :options="listType" optionLabel="label" class="p-col-width"/>
            </div>
          </div>
          <div v-if="selType.value == false" class="p-field p-grid">
            <label class="p-col-fixed cus-label-title">Số tháng <span style="color:red">*</span></label>
          </div>
          <div v-if="selType.value == false" class="p-field p-grid">
            <div class="p-col">
              <InputNumber v-model="value" id="value_add" class="input-text-box p-col-width" placeholder="Nhập số tháng"/>
            </div>
          </div>
          <div class="p-field p-grid">
            <label class="p-col-fixed cus-label-title">Giá (VNĐ) <span style="color:red">*</span></label>
          </div>
          <div class="p-field p-grid">
            <div class="p-col">
              <InputNumber v-model="price" id="price_add" class="input-text-box p-col-width" placeholder="Nhập giá"/>
            </div>
          </div>
          <div class="p-field p-grid">
            <div class="p-col checkbox div-active-item">
              <Checkbox v-model="active" :binary="true"/>
              <label class="p-col-fixed text-active">Hiển thị </label>
            </div>
          </div>
        </div>
    <Toolbar class="fixed-bottom-toolbar">
      <template slot="right">
        <Button
          :label="$constants.TEXT_BUTTON.BACK"
          @click="backToList()"
          class="p-button-warning"
        />
        <Button
          v-if="mode == 'edit'"
          :label="$constants.TEXT_BUTTON.EDIT"
          @click="saveData()"
        />
        <Button
          v-else
          :label="$constants.TEXT_BUTTON.ADD"
          @click="saveData()"
        />
      </template>
    </Toolbar>
  </div>
  </form>
</template>

<script>
// import DataServices from '@/core/DataServices'
export default {
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      name: '',
      ordinal : 1,
      validationErrors: {},
      formMode: this.mode,
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      saving:false,
      description_short : '',
      description : '',
      show_home : false,
      active : true,
      price : 0,
      value : 1,
      images : [],
      imagesDeletes : [],
      selType : {value: false , label : "Theo tháng"},
      listType : [
        {value: false , label : "Theo tháng"},
        {value: true , label : "Vĩnh viễn"},
      ]
    }
  },
  mounted() {
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.name = this.model.name;
      this.description_short = this.model.description_short;
      this.description = this.model.description;
      if(this.model.is_forever == true){
        this.selType = {value: true , label : "Vĩnh viễn"}
      }
      this.price = this.model.price
      this.value = this.model.value
      this.active = this.model.active
      
    }
  },
  methods: {
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      let name_add = document.getElementById("name_add");
      if (this.name == undefined || this.name == null || this.name == ''){
        name_add.setCustomValidity("Vui lòng nhập tên gói!");
      }else {
        name_add.setCustomValidity("");
      }

      let description_short_add = document.getElementById("description_short_add");
      if (this.description_short == undefined || this.description_short == null || this.description_short == ''){
        description_short_add.setCustomValidity("Vui lòng nhập mô tả ngắn!");
      }else {
        description_short_add.setCustomValidity("");
      }
      let description_add = document.getElementById("description_add");
      if (this.description == undefined || this.description == null || this.description == ''){
        description_add.setCustomValidity("Vui lòng nhập mô tả!");
      }else {
        description_add.setCustomValidity("");
      }
      
      let value_add = document.getElementById("value_add");
      if(this.selType.value == true){
        console.log("OK")
      }else{
        if(this.value == 0){
          value_add.setCustomValidity("Vui lòng nhập tháng lớn hơn 0!");
        }else{
          if (this.value == undefined || this.value == null || this.value == ''){
            value_add.setCustomValidity("Vui lòng nhập tháng!");
          }else {
            if(this.value >= 100000){
              value_add.setCustomValidity("Vui lòng nhập tháng không quá 100.000!");
            }else{
              value_add.setCustomValidity("");
            }
          }
        }
      }
      

      let price_add = document.getElementById("price_add");
      if(this.price == 0){
        price_add.setCustomValidity("");
      }else{
        if (this.price == undefined || this.price == null || this.price == ''){
          price_add.setCustomValidity("Vui lòng nhập giá!");
        }else {
          if(this.fee >= 10000000000){
            price_add.setCustomValidity("Vui lòng nhập giá không quá 10 tỷ!");
          }else{
            price_add.setCustomValidity("");
          }
        }
      }
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },
  
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      
      var params = {
        name: this.name,
        description_short :this.description_short,
        description :this.description,
        price :this.price,
        value :this.value,
        active :this.active,
        selType: this.selType,
      }
      // console.log("params",params)
      // this.$commonFuction.is_loading(false);
      this.$emit('save', params);
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        if (files.length > 1) {
          alert("Chỉ được upload tối đa 1 ảnh");
          return;
        }
        let acceptTags = ["jpeg","jpg","gif","png","JPEG","JPG","PNG","GIF",];
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split(".").pop())) {
            alert(
              "Vui lòng upload ảnh có định dạng là: jpeg, jpg, gif, png!"
            );
            return;
          }
        }
        var arrTemp = []
        for (let file of files) {
          // if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            arrTemp.push(file);
          // }
        }
        
        if (this.images.length > 0 && this.images[0].file === "") {
          this.imagesDeletes.push(this.images[0]);
        }
        this.images = [];
        this.images = this.images.concat.apply(this.images, arrTemp);
      }
      event.target.value = "";
      // console.log("this.images",this.images)
      return;
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === '') {
        this.imgDelete.push(img)
      }
    }
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
  .p-invalid {
    color: red;
    margin: 1px 0px 0px 0px;
  }
  .text-active{
    margin: 0px 0px 0px 8px;
  }
  .div-active-item{
    max-width: 120px!important;
    width: 50px!important;
    display: flex!important;
    align-items: flex-start!important;
  }
  .one-active{
    margin-left: 5px!important;
  }
  /* .checkbox{
    display: flex!important;
  } */
  </style>
